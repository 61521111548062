<template>
  <BaseForm
    :id="id"
    ref="form"
    :disabled="disabled"
    :form-type="formType"
    :layout="layout"
    :loading="loading"
    :row="row"
    :select-models="selectModels"
  >
    <template #industry>
      <IndustrySelector
        ref="industrySelector"
        v-model="industryId"
        :mode="mode"
        :industry-required="true"
        @toggle-create="createNewSelected = true"
      />
      <CRInput
        v-if="showIndustryDescription"
        id="add-customer-industry-description"
        v-model="industryDescription"
        :rules="
          showIndustryDescription
            ? [
                isRequired(true, isNotEmpty, {
                  req: 'Industry Description Is Required',
                  error: 'Industry Description Is Required',
                }),
              ]
            : []
        "
        name="Industry Description"
        label="Industry Description"
        placeholder="Industry Description"
        cols="10"
        rows="1"
      />
    </template>
    <template #customerAccount>
      <div>Customer Account</div>
      <v-autocomplete
        v-model="select"
        item-value="customerAccountId"
        item-text="name"
        solo
        flat
        :disabled="disabled"
        :search-input.sync="search"
        :items="availableCustomerAccounts"
        :menu-props="{ closeOnContentClick: true }"
        append-icon="keyboard_arrow_down"
        return-object
        clearable
        @click:clear="clearSelectedAccount"
      >
        <template #prepend-item>
          <div>
            <div
              v-ripple
              class="create-account clear-account"
              @click="select = null"
            >
              Clear Selected Account
            </div>
            <v-divider class="customer-account-divider" />
          </div>
        </template>
      </v-autocomplete>
    </template>
    <template #bottom style="margin-top: 5px">
      <v-snackbar
        v-model="displayerr"
        color="error"
        :multi-line="isModeMultiLine"
        :timeout="0"
        :vertical="isModeVertical"
        class="errormsg"
        type="error"
      >
        {{ errmsg }}
        <v-btn
          id="customer-form-snackbar-button-close"
          dark
          flat
          @click="displayerr = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <div class="button-container">
        <v-btn
          id="customer-form-button-cancel-action"
          class="btn-secondaryaction"
          outline
          @click="cancelAction"
        >
          {{ isModeView ? 'All Customers' : 'Cancel' }}
        </v-btn>

        <v-btn
          v-if="isModeEdit"
          id="customer-form-button-save-customer"
          :disabled="loading"
          :loading="loading"
          class="btn-primaryaction"
          @click="submit"
        >
          Save Customer
        </v-btn>

        <v-btn
          v-if="isModeAdd"
          id="customer-form-button-add-customer"
          :disabled="loading"
          :loading="loading"
          class="btn-primaryaction"
          @click="submit"
        >
          Add Customer
        </v-btn>

        <v-btn
          v-if="isModeView"
          id="customer-form-button-edit-customer"
          class="btn-primaryaction"
          @click="enableEdit"
        >
          Edit Customer
        </v-btn>
      </div>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import customers from '@/services/customers'
import customerAccounts from '@/services/customerAccounts'
import { authComputed } from '@/state/helpers'
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'
import { filter } from '@/utils/filter'
import IndustrySelector from '@/components/IndustrySelector.vue'
import industries from '@/services/industries'

export default {
  name: 'CustomerForm',
  components: {
    BaseForm,
    IndustrySelector,
  },
  props: {
    id: { type: String, default: () => null },
    mode: { type: String, default: () => 'view' },
  },
  data() {
    return {
      select: '',
      industryId: null,
      showIndustryDescription: false,
      industryDescription: '',
      displayerr: false,
      errmsg: '',
      loading: false,
      disabled: false,
      selectModels: {},
      row: {},
      search: null,
      layout: [],
      formType: '',
      availableCustomerAccounts: [],
      isRequired,
      isNotEmpty,
      validateEmail,
    }
  },
  computed: {
    ...authComputed,
    selectedIndustryId() {
      return this.industryId
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeVertical() {
      return this.mode === 'vertical'
    },
    isModeMultiLine() {
      return this.mode === 'multi-line'
    },
  },
  watch: {
    search() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      if (!this.immediate) {
        this.debounce = setTimeout(() => {
          this.immediate = true
          this.searchCustomerAccounts()
        }, 500)
        return
      }

      this.searchCustomerAccounts()
    },
    selectedIndustryId() {
      this.shouldShowIndustryDescription()
    },
  },
  metaInfo() {
    const meta = {}
    if (this.isModeEdit) {
      meta.title = `Edit: ${this.row.firstName ? this.row.firstName : ''} ${
        this.row.lastName ? this.row.lastName : ''
      }`
    }

    if (this.isModeView) {
      meta.title = `${this.row.firstName ? this.row.firstName : ''} ${
        this.row.lastName ? this.row.lastName : ''
      }`
    }
    return meta
  },
  async mounted() {
    // fetch companies info about the thing over here
    this.searchCustomerAccounts()
    this.formType = 'Add Customer'

    if (this.isModeView) {
      this.disabled = true
    }
    if (this.isModeAdd) {
      this.row = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyId: null,
        industryId: null,
        customerAccountId: null,
        customerAccountName: null,
        active: true,
      }
    } else {
      const { data } = await customers.getCustomer(this.id)
      this.row = data?.customer

      this.formType = `${this.isModeEdit ? 'Edit: ' : ''} ${
        this.row.firstName
      } ${this.row.lastName}`
    }
    this.select = this.row?.customerAccount
    this.layout = [
      {
        id: 1,
        fields: [
          {
            id: 'firstName',
            label: 'First Name',
            key: 'firstName',
            type: 'text',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'First Name Required',
                error: 'First Name Required',
              }),
            ],
          },
          {
            id: 'lastName',
            label: 'Last Name',
            key: 'lastName',
            type: 'text',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Last Name Required',
                error: 'Last Name Required',
              }),
            ],
          },
        ],
      },
      {
        id: 2,
        fields: [
          {
            id: 'email',
            label: 'Email',
            key: 'email',
            type: 'text',
            rules: [
              isRequired(true, validateEmail, {
                req: 'Email Required',
                error: 'Email required and must be valid',
              }),
            ],
          },
          {
            id: 'phone',
            label: 'Phone',
            key: 'phone',
            type: 'phone',
            rules: [
              isRequired(true, isNotEmpty, {
                req: 'Phone Required',
                error: 'Phone required and must be valid',
              }),
            ],
          },
        ],
      },
    ]

    this.layout[0].fields.push({
      id: 'industry',
      label: 'industry',
      key: 'industry',
      type: 'slot',
    })

    this.layout[1].fields.push({
      id: 'organization',
      label: 'Organization',
      key: 'organization',
      type: 'text',
    })

  },
  methods: {
    validate() {
      if (
        this.$refs.industrySelector &&
        !this.$refs.industrySelector.validate()
      ) {
        return false
      }
      const test = this.$refs.form.validateAll()
      if (test.length > 0) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be required or are invalid.',
          true
        )
        return false
      }
      return true
    },
    disableAndRevert() {
      this.disabled = true
      this.mode = 'view'
      this.$router.push(`/customers/view/${this.id}`)
    },
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
    },
    async shouldShowIndustryDescription() {
      const industryResponse = await industries.getIndustry(this.industryId)
      const industryData = industryResponse.data?.industry
      if (industryData.isOther) {
        this.showIndustryDescription = true
      } else {
        this.industryDescription = ''
        this.showIndustryDescription = false
      }
    },
    async submit() {
      this.loading = true
      if (!this.validate()) {
        this.loading = false
        return
      }
      const user = this.currentUser
      this.row.companyId = user.companyId
      this.row.customerAccountId = this.select
        ? this.select.customerAccountId
        : null

      const payload = JSON.parse(JSON.stringify(this.row))
      payload.phone = payload.phone.replace(/[^0-9]/g, '')
      payload.industryId = this.industryId
      payload.industryDescription = this.industryDescription
      payload.customerAccountName = this.select?.name
      try {
        if (this.isModeAdd) {
          await customers.addCustomer({
            id: this.id,
            payload,
          })
        } else {
          await customers.modifyCustomer({
            id: this.id,
            payload,
          })
        }
      } catch (e) {
        this.loading = false
        this.displayWarning = true
        this.warningMessage = e
        const response = e.request?.response
        let message = 'Customer failed to save.'
        if (response) {
          message = JSON.parse(response)?.message
        }
        this.$store.dispatch('app/showAlert', {
          message: message,
          type: 'error',
        })
        return
      }

      this.loading = false
      this.disabled = !this.disabled

      this.$router.push(`/customers${this.id ? `/view/${this.id}` : ''}`)
      this.$store.dispatch('app/showAlert', {
        message: 'Customer info saved successfully.',
        type: 'success',
      })
    },
    async searchCustomerAccounts() {
      this.immediate = false

      const filterObjects = []
      const affilFilter = filter()

      if (this.search) {
        filterObjects.push({
          column: {
            _t_id: 'text_search_cust',
            prop: 'name',
            filterType: 'contains',
          },
          value: this.search,
        })
      }

      filterObjects.forEach((fil) => {
        const parent = affilFilter.createParent('and')
        affilFilter.add(parent, fil)
      })

      const filterParams = affilFilter.asQueryParams()

      this.availableCustomerAccounts = await customerAccounts
        .getCustomerAccounts({
          filters: filterParams,
          pageSize: 20,
          page: 1,
        })
        .then(({ data }) => {
          return data.resultList
        })
        .catch((e) => {})
    },
    clearSelectedAccount() {
      this.search = null
      this.select = null
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.mode = 'edit'
        this.$router.push(`/customers/edit/${this.id}`)
      }

      this.disabled = !this.disabled
    },
    backToTable() {
      this.$router.push('/customers')
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
  },
}
</script>

<style lang="scss" scoped>
.create-account {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 2;
  color: $blue;
  cursor: pointer;

  &.clear-account {
    color: $red;
  }
}

::v-deep.customer-account-divider {
  margin: 0 !important;
}

.cr-base-form-display-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 20%;
  margin-bottom: 50%;
}

.button-container {
  display: flex;
  margin-top: 2%;
  margin-right: 3%;
}

.errormsg {
  top: 90%;
  left: -2%;
}

::v-deep input:disabled {
  background-color: $blue-dull;
}

::v-deep .v-snack {
  position: absolute;
}

::v-deep .box-height.industry {
  max-height: unset !important;
}
</style>
